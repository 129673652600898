@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .primary-interaction{
    @apply text-primary hover:text-primary-hover ;
  }
  .primary-interaction-bg{
    @apply bg-primary hover:bg-primary-hover ;
  }
}

@layer components {
  .card{
    @apply bg-gradient-to-br p-6 from-slate-800 from-20% to-slate-900 rounded-2xl shadow-lg ;
  }
  .card-light{
    @apply mx-0 ;
  }
  .card-dark{
    @apply bg-slate-900 rounded-xl shadow-lg ;
  }
  .circle-button{
    @apply flex justify-center items-center rounded-full p-4

    bg-slate-700 hover:bg-slate-600 aspect-square text-white text-2xl;
  }
}
